// New dialog added by Fernando, Jan 10 2025
import React, { useContext, useEffect, useRef, useState } from "react";
import { Divider, Grid, Typography, Box, Button } from "@mui/material";
import { Line } from "react-chartjs-2";
import { chartContext } from "../../common/ChartContex";
import { createArrayBetween } from "../../common/formatter";
import Tooltip from "@mui/material/Tooltip";
import BottomImg from "../../assets/images/square-images/infodown.svg";
import UPImg from "../../assets/images/square-images/infoup.svg";
import CloseImg from "../../assets/images/square-images/close.svg";

import BorrowedIcon from "../../assets/images/reshot-icon-chevron-arrow-backward-circle-4CQNS58E3L.svg";
import BankedIcon from "../../assets/images/reshot-icon-chevron-arrow-forward-circle-HL73ANZSEF.svg";
import PenaltyIcon from "../../assets/images/reshot-icon-chevron-arrow-downward-circle-FAVPWX7LSE.svg";
import multipleActions from "../../assets/images/reshot-icon-chevron-circle-3YSZW6UHAN.svg";


export default function FEUMIntensityChart({ customClass, optionsFEUM, textBlack, show, }) {
  const chartData = useContext(chartContext);
  useEffect(() => { }, [chartData]);

  const feum_JSON = chartData?.myFEUMchart;

  // So far we need only one array. Later we will add the emissions reference
  const [vesselFeumValue, setVesselFeumValue] = useState([]);
  const [limitFeumValue, setLimitFeumValue] = useState([]);

  const vesselLineData = chartData?.myFEUMchart?.feum_details;
  const limitLineData = chartData?.myFEUMchart?.eufmintensityLimit;

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Chart Information");
  const [data, setData] = useState(null);

  const complianceYear = createArrayBetween(
    feum_JSON?.firstYear,
    feum_JSON?.lastYear
  );

  const handleFeumChart = (title) => {
    setTitle(title);
    setData(feum_JSON.comments);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    feumChartRef?.current?.scrollIntoView({ behavior: "auto" });
  };

  const feumChartRef = useRef(null);
  const feumChartInfo = useRef(null);
  const iconsRef = useRef({}); 

  useEffect(() => {
    if (open) {
      feumChartInfo.current.scrollIntoView({ behavior: "auto" });
    }
  }, [open]);




  // Initialize the images once and store them in `iconsRef`
  useEffect(() => {
    if (feum_JSON) {
      const borrowedIconImg = new Image();
      borrowedIconImg.src = BorrowedIcon;

      const bankedIconImg = new Image();
      bankedIconImg.src = BankedIcon;

      const penaltyIconImg = new Image();
      penaltyIconImg.src = PenaltyIcon;

      const multipleActionsImg = new Image();
      multipleActionsImg.src = multipleActions;

      iconsRef.current = {
        borrowedIconImg,
        bankedIconImg,
        penaltyIconImg,
        multipleActionsImg,
      };
    }
  }, [feum_JSON]);

  // Function to determine the correct icon
  const determineIcon = (feumElement) => {
    const { borrowedIconImg, bankedIconImg, penaltyIconImg, multipleActionsImg } = iconsRef.current;

    const isBorrowed = feumElement.borrowedFromNextYear_gco2eq > 1000;
    const isBanked = feumElement.bankedToNextYear_gco2eq > 1000;
    const hasPenalty = feumElement.externalCredits_gCO2eq > 0;

    if ((isBorrowed && isBanked) || (isBorrowed && hasPenalty) || (isBanked && hasPenalty)) {
      return multipleActionsImg;
    }

    if (isBorrowed) {
      return borrowedIconImg;
    }

    if (isBanked) {
      return bankedIconImg;
    }

    if (hasPenalty) {
      return penaltyIconImg;
    }

    return "circle"; // Default case if none of the conditions apply
  };

  // Effect to update chart labels and datasets based on `chartData`
  useEffect(() => {
    if (feum_JSON) {
      const vesselLine = vesselLineData.map((entry) => {
        const { vesselName, colorHex, pointsByYear } = entry;

        const sortedYearData = Object.entries(pointsByYear)
          .sort(([yearA], [yearB]) => Number(yearA) - Number(yearB))
          .map(([year, feumElement]) => {
            const icon = determineIcon(feumElement);
            return {
              x: +year,
              y: +(feumElement.reportedIntensity_gCo2eq_MJ || 0).toFixed(2),
              vesselName,
              reportedIntensity_gCo2eq_MJ: feumElement.reportedIntensity_gCo2eq_MJ || 0,
              borrowedFromNextYear_gco2eq: feumElement.borrowedFromNextYear_gco2eq || 0,
              bankedToNextYear_gco2eq: feumElement.bankedToNextYear_gco2eq || 0,
              unaidedIntensity_gCo2eq_MJ: feumElement.unaidedIntensity_gCo2eq_MJ || 0,
              externalCredits_gCO2eq: feumElement.externalCredits_gCO2eq || 0,
              icon,
            };
          });

          return {
            type: "line",
            label: vesselName,
            data: sortedYearData,
            backgroundColor: colorHex,
            borderColor: colorHex,
            borderWidth: 2,
            lineTension: 0,
            pointRadius: (context) => (context.raw.icon === "circle" ? 3 : 6),
            pointStyle: (context) => {
              return context.raw.icon ;
            },
            pointHoverRadius: 6,
            borderDash: [0, 0],
          };
        });

      setVesselFeumValue(vesselLine);

      // 2. Limit line  ====================================================
      const limitLine = {
        type: "line",
        label: "FEUM Intensity Limit",
        data: Object.entries(limitLineData)
          .sort(([yearA], [yearB]) => Number(yearA) - Number(yearB))
          .map(([year, limit]) => ({
            x: +year,
            y: +limit.toFixed(2),
          })),
        backgroundColor: "#E2C767",
        borderColor: "#E2C767",
        borderWidth: 2,
        lineTension: 0.,
        pointRadius: 2, // Very small point
        borderDash: [5, 5], // Dashed line for distinction
      };

      setLimitFeumValue(limitLine);

    }
  },  [feum_JSON, vesselLineData, limitLineData]);

  return (
    <Grid
      ref={feumChartRef}
      container
      className={`px-4 pt-[21px] pb-[22.57px] h-full  ${customClass}`}
    >
      <Box className="flex justify-between w-full">
        <Box>
          <Typography
            variant="subtitle1"
            className={`w-full flex justify-start font-medium text-xl leading-[25px] text-updated-primary-text ${textBlack}`}
          >
            {feum_JSON?.chartTitle}
          </Typography>
          {feum_JSON?.chartSubTitle && (
            <Typography
              variant="subtitle2"
              className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
            >
              {feum_JSON?.chartSubTitle}
            </Typography>
          )}
        </Box>

        {feum_JSON?.comments && show && (
          <Box>
            <Tooltip title="Notes and Sources" arrow>
              <Button
                type="submit"
                onClick={() => {
                  handleFeumChart(feum_JSON?.chartTitle);
                }}
              >
                <img src={open ? UPImg : BottomImg} alt="Information" />
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Divider className="w-full border border-solid border-mortar-grey my-4 " />
      <Grid item xs={12} className="compliance-chart-container">
        <Line
          data={{
            labels: complianceYear?.map((i) => i),
            datasets: [limitFeumValue, ...vesselFeumValue],
          }}
          options={optionsFEUM} />
      </Grid>
      {open && (
        <div ref={feumChartInfo}>
          <Divider className="w-full border border-solid border-mortar-grey my-4" />
          <div className="log-outmodal info">
            <div className="info-header">
              <Grid className="flex justify-start items-start ">
                <Typography
                  variant="h3"
                  className="ml-0 p-0 normal-case text-xl font-medium text-updated-primary-text"
                >
                  {title}
                </Typography>
              </Grid>
              <Button onClick={handleClose}>
                <img src={CloseImg} alt="close" />
              </Button>
            </div>
            {data && (
              <div
                className="text-base mt-[10px] info-text text-white"
                dangerouslySetInnerHTML={{ __html: data }}
              />
            )}
          </div>
        </div>
      )}
    </Grid>
  );
}
