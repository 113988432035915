import { buttonClasses } from "@mui/material";
import tabActive from "../assets/images/tab-active-bg.svg";
import tabBgImg from "../assets/images/tab-bg.svg";
import resultBtnActive from "../assets/images/result-btn-active.svg";
import resultTabBgImg from "../assets/images/result-bg1.svg";
import resultTabBgFocus from "../assets/images/result-tab-focus-bg.svg";
import tabActiveForMobile from "../assets/images/number-tab-bg.png";
import tabBgImgForMobile from "../assets/images/number-tab-active-bg.png";
import tabBgImgForMobileFocus from "../assets/images/mobile-focus.svg";

export const MenuProps = {
  PaperProps: {
    sx: {
      "& .MuiMenu-list": {
        paddingBottom: "18px",
      },
      "& .MuiMenuItem-root": {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "21px",
        color: "#1E4857",
      },
      "& .Mui-selected": {
        backgroundColor: "rgba(44, 142, 198, 0.08)",
      },
    },
  },
};

export const GroupMenuProps = {
  PaperProps: {
    sx: {
      "& .MuiMenu-list": {
        paddingBottom: "18px",
      },
      "& .MuiListSubheader-root": {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "21px",
        color: "#1E4857",
        marginTop: "16px",
        marginBottom: "2px",
      },
      "& .MuiMenuItem-root": {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "21px",
        color: "#1E4857",
        paddingLeft: "37px",
      },
    },
  },
};

export const ButtonStartIcon = {
  ml: 1,
  "&.MuiButtonBase-root:hover": {
    bgcolor: "transparent",
  },
  [`& .${buttonClasses.startIcon} > *:nth-of-type(1)`]: {
    fontSize: "25px",
  },
};

export const TabStyle = {
  "& button": {
    background: "#EFEFEF",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#979797",
    textTransform: "inherit",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > .tab-bottom-span": {
      width: "98%",
      bottom: "-2px",
      backgroundColor: "white",
      position: "relative",
    },
  },
  "& .Mui-selected": {
    backgroundColor: "#ffffff",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
    color: "#275DC5 !important",
    textTransform: "inherit",
    borderRight: "1px solid #9E9E9E",
    borderLeft: "1px solid #9E9E9E",
    borderTop: "1px solid #9E9E9E",
  },
};

export const customTable = {
  "& .MuiTableCell-sizeMedium ": {
    padding: "0", // <-- arbitrary value
    border: "none",
    paddingBottom: "15px",
    paddingTop: "15px",
    textAlign: "center",
  },
  [`& th.MuiTableCell-sizeMedium:nth-of-type(1)`]: {
    width: "50px",
    textAlign: "left",
  },
};

export const tabDesign = {
  "& .MuiTabs-indicator": {
    display: "none",
  },

  "& .MuiButtonBase-root": {
    "& .MuiSvgIcon-root": {
      fill: "#2C8EC6",
      height: "1.5em",
      width: "1.5em",
    },
  },

  "& .MuiTabs-flexContainer": {
    height: "100%",
  },
  "& button": {
    padding: 0,
    height: "100%",
    width: "100%",
  },
  "& button .MuiBox-root": {
    background: `url(${tabBgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    height: "100%",
    width: "100%",
    justifyContent: "center",
  },
  "& .tab-btn-gradient": {
    background: `url(${tabActive})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    color: "#242C3B",
  },

  "& .focus-class .MuiBox-root": {
    background: `url(${resultTabBgFocus})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    color: "#242C3B",
  },

  "& .tab-btn-gradient .MuiBox-root": {
    background: "unset",
  },
  "& .MuiTab-root": {},
  "& .Mui-selected": {},
  "& .MuiTouchRipple-root": {
    display: "none",
  },

  "@media (max-width: 599px)": {
    "& button .MuiBox-root": {
      background: `url(${tabBgImgForMobile})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain", // Change tabBgImg for mobile view
    },
    "& .tab-btn-gradient": {
      background: `url(${tabActiveForMobile})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
    },

    "& .focus-class .MuiBox-root": {
      background: `url(${tabBgImgForMobileFocus})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
    },
  },
};

export const CustomSliderStyles = {
  height: "8px",
  "& .MuiSlider-thumb": {
    height: "20px",
    width: "20px",
    borderRadius: "20px",
    border: "3px solid #2C8EC6",
    backgroundColor: "#ffffff",
    boxShadow: "3px 4px 7px 0px rgba(255, 255, 255, 0.10)",
  },
  "& .MuiSlider-track": {
    borderRadius: "15px",
    border: "3px solid #2C8EC6",
    backgroundColor: "#2C8EC6",
  },
  "& .MuiSlider-rail": {
    color: "#EEF1F5",
  },
};

export const mapOptions = {
  styles: [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        { color: "#ffffff" }, // Set the text color to white
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        { color: "#000000" }, // Set the text stroke color to black
        { visibility: "on" },
      ],
    },
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [
        { color: "#A7E2EF" }, // Set the background color to dark
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        { color: "#2C8EC6" }, // Set the background color to red
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        { visibility: "off" }, // Hide street labels
      ],
    },
  ],
  minZoom: 3, // Set the minimum zoom level
  maxZoom: 6, // Set the maximum zoom level
};

export const tabDesign1 = {
  "& .MuiTabs-indicator": {
    display: "none",
  },

  "& .MuiButtonBase-root": {
    "& .MuiSvgIcon-root": {
      fill: "#2C8EC6",
      height: "1.5em",
      width: "1.5em",
    },
  },

  "& button": {
    padding: 0,
    height: "53px",
  },
  "& .tab-btn": {
    background: `url(${resultTabBgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain !important",
    height: "100%",
    width: "100%",
    color: "#FFF",
    justifyContent: "center",
    padding: "40px",
    marginTop: "1.5em",
  },
  "& .tab-btn-gradient": {
    background: `url(${resultBtnActive})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain !important",
    color: "#242C3B",
    fontSize: "16px",
    fontWeight: 500,
    padding: "40px",
    marginTop: "1.5em",
  },

  "& .tab-btn-gradient .MuiBox-root": {
    background: "unset",
  },
  "& .MuiTab-root": {},
  "& .Mui-selected": {},
  "& .MuiTouchRipple-root": {
    display: "none",
  },
};

export const tabDesignNew = {
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTabs-flexContainer": {
    height: "100%",
  },

  "& .MuiButtonBase-root": {
    "& .MuiSvgIcon-root": {
      fill: "#72a653",
      height: "1em",
      width: "1em",
    },
  },

  "& button": {
    padding: "1em 2em",
    height: "100%",
    color: "#2C8EC6",
    backgroundColor: "#FFFFFF",
    border: "1px solid #2C8EC6",
  },
  "& button .MuiBox-root": {
    background: `url(${tabBgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    height: "100%",
    width: "100%",

    justifyContent: "center",
  },
  "& .tab-btn-gradient": {
    background: `url(${tabActive})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    color: "#2C8EC6",
    fontSize: "16px",
    fontWeight: 500,
  },

  "& .tab-btn-gradient .MuiBox-root": {
    background: "unset",
  },
  "& .MuiTab-root": {},
  "& .Mui-selected": {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1em",
    padding: "1em 2em",
    cursor: "pointer",
    borderRadius: "7px",
    border: " 1px solid transparent",
    backgroundColor: "#2C8EC6",
    color: "#FFFFFF",
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
};
